import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import BasicInformation from "./BasicInformation";
import Education from "./Education";
import Experience from "./Experience";

const ResumeDetail = ({ resumeDetail }) => {
  const [is_education_segment_visible, setEducationCollapse] =
    useState<any>(false);
  const [basicInformation, setBasicInformation] = useState<any>(false);
  const [custom_segment_visible, setCustomSegmentCollapse] = useState<any>({});
  const [is_experience_segment_visible, setExperienceCollapse] =
    useState<any>(false);

  const showOrCloseCustomSegment = (index) => {
    if (custom_segment_visible[index]) {
      let update = { ...custom_segment_visible };

      update[index] = false;
      setCustomSegmentCollapse(update);
    } else {
      let update = { ...custom_segment_visible };
      update[index] = true;
      setCustomSegmentCollapse(update);
    }
  };

  console.log(custom_segment_visible);

  return (
    <>
      <div className="flex justify-between gap-3 mt-5">
        <span className="text-lg text-black dark:text-zink-200">
          Basic Information
        </span>
        <button onClick={() => setBasicInformation(!basicInformation)}>
          {" "}
          See information Resume{" "}
        </button>
      </div>
      {basicInformation && <BasicInformation resumeDetail={resumeDetail} />}
      <div className="flex justify-between gap-3 mt-5">
        <span className="text-lg text-black dark:text-zink-200">
          Education Segment
        </span>
        <button
          onClick={() => setEducationCollapse(!is_education_segment_visible)}
        >
          {is_education_segment_visible ? "Hide Details" : " See Details"}
        </button>
      </div>
      {is_education_segment_visible ? (
        <div className="card-body">
          <Education resumeDetail={resumeDetail} />
        </div>
      ) : null}
      <div className="flex justify-between gap-3 mt-5">
        <span className="text-lg text-black dark:text-zink-200">
          Experience Segment
        </span>
        <button
          onClick={() => setExperienceCollapse(!is_experience_segment_visible)}
        >
          {is_experience_segment_visible ? "Hide Details" : " See Details"}
        </button>
      </div>
      {is_experience_segment_visible ? (
        <div className="card-body">
          <Experience resumeDetail={resumeDetail} />
        </div>
      ) : null}
      {resumeDetail?.custom_segments?.map((item, index) => (
        <div>
          <div className="flex justify-between gap-3 mt-5">
            <span className="text-lg text-black dark:text-zink-200">
              {item.title}
            </span>
            <button onClick={() => showOrCloseCustomSegment(index)}>
              {custom_segment_visible[index] ? "Hide Details" : " See Details"}
            </button>
          </div>
          {custom_segment_visible[index] ? (
            <div className="card-body">
              <div className="border card border-custom-200 dark:border-custom-800">
                <div className="card-body">
                  {item?.original_input?.map((text) => (
                    <p
                      key={text._id}
                      className="mb-4 text-slate-500 dark:text-zink-200"
                    >
                      {text.description}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default ResumeDetail;
