import callApi from "Common/utils/apiCaller";
import React, { useEffect, useState } from "react";
import "swiper/css";
import { TailSpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import moment from "moment";
import ResumeDetail from "./ResumeDetail";
import Drawer from "../../../Common/Components/Drawer";
import { X } from "lucide-react";

const ResumeList = ({
  customer_id,
  handleViewDetailClick,
  shows,
  setShows,
}) => {
  document.title = "Resume | curriculo";
  const [is_detailed_resume_visible, setDetailedResumeView] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resumeList, setResumeList] = useState<any>();
  const [eventData, setEventData] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [page, setPage] = useState<any>({
    pageNum: 1,
    pageSize: 20,
  });
  const [totalItems, setTotalItems] = useState<any>();
  const [mediaType, setMediaType] = useState<any>();
  const perPageData = 2;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;
  const [resumeDetail, setResumeDetail] = useState<any>();
  useEffect(() => {
    if (customer_id) {
      fetchResumesForCustomer(customer_id);
    }
  }, [customer_id]);

  type ApiResponse<T> = {
    status: string;
    data: T;
  };

  type Resume = {};

  type Media = {
    media: string;
  };

  async function fetchResumesForCustomer(customer_id: string) {
    try {
      setLoader(true);
      const res: ApiResponse<{
        resumeList: Resume[];
        resumeCount: number;
      }> = await callApi(`v1/resume/list`, "post", {
        pageNum: page.pageNum,
        pageSize: page.pageSize,
        filters: {
          customer_id: customer_id,
        },
      });
      // setResumeDetail([]);
      if (res && res.status === "Success") {
        setLoader(false);
        setResumeList(res.data?.resumeList);
        setTotalItems(res.data?.resumeCount);
      }
    } catch (error) {
      console.error("An error occurred while fetching posts:", error);
    }

    console.log(resumeList);
  }

  const fetchResumeDetails = async (resume_id) => {
    setLoader(true);
    console.log("click");
    const res = await callApi(
      `v1/customer/resume/${resume_id}/info`,
      "post",
      {}
    );

    setLoader(false);
    if (res && res?.status === "Success") {
      setResumeDetail(res.data);
      setDetailedResumeView(true);
    }
    // console.log(resumeDetail);
    handleViewDetailClick(true);
    setShows(true);
  };
  const toggle = () => {
    if (shows) {
      setShows(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShows(true);
      setEventData("");

      // validation.resetForm();
    }
  };

  return (
    <>
      <Drawer.Header
        className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
        closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
      >
        <Drawer.Title className="text-15">
          {is_detailed_resume_visible ? "Detailed Resume" : "Resume List"}
        </Drawer.Title>
        <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
      </Drawer.Header>
      <Drawer.Body className="h-full p-4 overflow-y-auto">
        {loader ? (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              zIndex: 6,
              background: "#fff",
              opacity: "1",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="flex justify-center">
              <TailSpin visible={true} height="50" width="50" color="#4fa94d" />
            </div>
          </div>
        ) : null}
        {is_detailed_resume_visible ? (
          <>
            <a href="#" onClick={() => setDetailedResumeView(false)}>
              Back to List
            </a>
            <ResumeDetail resumeDetail={resumeDetail} />
          </>
        ) : null}
        {!is_detailed_resume_visible ? (
          <>
            <div className="grid grid-cols-1 gap-5 my-5 lg:grid-cols-12 2xl:grid-cols-12">
              {resumeList?.map((item: any, i: any) => (
                <div className="lg:col-span-12 2xl:col-span-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="flex gap-3 mb-4">
                        <p className="text-slate-500 dark:text-zink-200">
                          {item?.about_me}
                        </p>
                      </div>
                      <div className="flex justify-between gap-3 mb-4">
                        <span className="text-slate-500 dark:text-zink-200">
                          {moment(item?.created_at).format("DD MMM YY HH:mm")}
                        </span>
                        <button onClick={() => fetchResumeDetails(item?._id)}>
                          {" "}
                          See Detailed Resume{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </Drawer.Body>
    </>
  );
};

export default ResumeList;
