import { useState, createContext, useContext, ReactNode } from "react";
import { BlogPost } from "./blog-type";

type BlogContextType = {
	blogs: BlogPost[];
	setBlogs: React.Dispatch<React.SetStateAction<BlogPost[]>>;
};

const BlogContext = createContext<BlogContextType | null>(null);

export const useBlogContext = (): BlogContextType => {
	const context = useContext(BlogContext);
	if (!context) {
		throw new Error("useBlogContext must be used within a BlogProvider");
	}
	return context;
};

export function BlogProvider({ children }: { children: ReactNode }) {
	const [blogs, setBlogs] = useState<BlogPost[]>([]);

	return (
		<BlogContext.Provider value={{ blogs, setBlogs }}>
			{children}
		</BlogContext.Provider>
	);
}
