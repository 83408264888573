import React from "react";
import moment from "moment";

const Education = ({ resumeDetail }) => {
  return (
    <div>
        {resumeDetail?.education_segments?.map((item, index) => (
            <div>
                {item.original_input?.map((data, dataIndex) => (
                    <div key={data._id} className="border card border-custom-200 dark:border-custom-800">
                        <div className="card-body">
                            <p className="mb-2 text-gray-600 dark:text-gray-400">
                        <span className="mr-1 ">
                          Field of Study:
                        </span>{" "}
                                {data.field_of_study}
                            </p>
                            <p className="mb-2 text-gray-700 dark:text-gray-300">
                                <span className="mr-1 ">Institution:</span>{" "}
                                {data.institution_name}
                            </p>
                            <p className="mb-2 text-gray-700 dark:text-gray-300">
                                <span className="mr-1 ">Degree:</span>{" "}
                                {data.degree}
                            </p>
                            <p className="text-gray-700 dark:text-gray-300">
                                <span className="mr-1">Location:</span>{" "}
                                {data.location}
                            </p>
                          <p className="text-gray-700 dark:text-gray-300">
                                <span className="mr-1">Start Date:</span>
                                {moment(new Date(data.start_date)).format("DD MMM YYYY")}
                            <span className="mr-1" style={{marginLeft: "20px"}}>End Date:</span>{"        "}
                            {moment(new Date(data.end_date)).format("DD MMM YYYY")}
                            </p>
                            <p className="mb-2 text-gray-700 dark:text-gray-300">
                                <span className="mr-1 ">Description:</span>{" "}
                                {data.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        ))}
    </div>
  );
};

export default Education;
