import { PaginationState } from "@tanstack/react-table";
import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import callApi from "Common/utils/apiCaller";
import { Download, Plus, Search, SlidersHorizontal, X } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Pencil, Trash2 } from "lucide-react";
import _ from "lodash";
import DeleteModal from "Common/DeleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Drawer from "Common/Components/Drawer";

const Admins = () => {
  document.title = "Admin | Curriculo";
  const [loader, setLoader] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });
  const [selectedImage, setSelectedImage] = useState<any>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  const debouncedHandler = useCallback(
    _.debounce((user: any) => {
      return fetchDateWithFilters(user);
    }, 700),
    []
  );

  async function fetchDateWithFilters(searchByName: any) {
    let filters: { [index: string]: any } = {};
    if (searchByName) {
      filters.qtext = searchByName;
    }
    // setLoader(true);
    const localData = await callApi("v1/admin/list", "post", {
      pageNum: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      filters,
    });
    // setLoader(false);
    if (localData.status === "Success") {
      setAdminData(localData.data.adminList);
      setPageCount(Math.ceil(localData.data.adminCount / pagination.pageSize));
      setTotalItems(localData.data.adminCount);
    }
  }

  useEffect(() => {
    fetchAdminList();
  }, [pagination, render]);

  const fetchAdminList = async () => {
    setLoader(true);
    const localData = await callApi("v1/admin/list", "post", {
      pageNum: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    });
    setLoader(false);
    if (localData.status === "Success") {
      setAdminData(localData.data.adminList);
      setPageCount(Math.ceil(localData.data.adminCount / pagination.pageSize));
      setTotalItems(localData.data.adminCount);
    }
  };

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["name", "designation", "location", "email", "status"];
    debouncedHandler(search);
  };

  const options = [
    { value: "Select...", label: "Select Status" },
    { value: "Verified", label: "Verified" },
    { value: "Waiting", label: "Waiting" },
    { value: "Rejected", label: "Rejected" },
    { value: "Hidden", label: "Hidden" },
  ];

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      // setUser(userList);
    } else {
      const filteredUsers = adminData.filter(
        (data: any) => data.status === selectedOption.value
      );
      // setUser(filteredUsers);
    }
  };

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };

  const handleDelete = async () => {
    if (eventData) {
      //dispatch(onDeleteEmployee(eventData.id));
      setLoader(true);
      const res = await callApi(`v1/admin/${eventData?._id}/update`, "post", {
        admin: { is_deleted: true },
      });
      setLoader(false);
      if (res && res?.status === "Success") {
        setDeleteModal(false);
        setRender(!render);
        toast("Removed successfully");
      }
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // employeeId: (eventData && eventData.employeeId) || "",
      contact_name: (eventData && eventData.contact_name) || "",
      address: (eventData && eventData.address) || "",
      password: (eventData && eventData.password) || "",
      // pincode: (eventData && eventData.pincode) || "",
      gender: (eventData && eventData.gender) || "",
      contact_email: (eventData && eventData.contact_email) || "",
      // designation: (eventData && eventData.designation) || "",
      contact_phone: (eventData && eventData.contact_phone) || "",
    },
    validationSchema: Yup.object({
      contact_name: Yup.string().required("Please Enter Name"),

      contact_email: Yup.string().required("Please Enter Email"),
      contact_phone: Yup.string().required("Please Enter Phone"),
      password: Yup.string().required("Please Enter Password"),
      address: Yup.string().required("Please Enter Address"),
      // pincode: Yup.string().required("Please Enter Pincode"),
      gender: Yup.string().required("Please Enter Gender"),
    }),

    onSubmit: async (values) => {
      console.log("ddfo");
      if (isEdit) {
        const updateData = {
          _id: eventData ? eventData._id : 0,
          ...values,
        };
        // update user

        const res = await callApi(`v1/admin/${eventData?._id}/update`, "post", {
          admin: values,
        });
        if (res && res?.status === "Success") {
          setRender(!render);
          toast("Updated successfully");
        }
      } else {
        const res = await callApi(`v1/admin/new`, "post", {
          admin: {
            contact_name: values?.contact_name,
            password: values?.password,
            // pincode: values?.pincode,
            // address: values?.address,
            contact_email: values?.contact_email,
            contact_phone: values?.contact_phone,
            address: values?.address,
            gender: values?.gender,
            country_code: "91",
          },
        });
        if (res && res?.status === "Success") {
          setRender(!render);
          toast("Admin created successfully");
        }
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setSelectedImage("");
    } else {
      setShow(true);
      setEventData("");
      setSelectedImage("");
      validation.resetForm();
    }
  }, [show, validation]);

  const adminListColumns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "contact_name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Email",
        accessorKey: "contact_email",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Phone",
        accessorKey: "contact_phone",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Role",
        accessorKey: "role",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              to="#!"
              data-modal-target="addEmployeeModal"
              className="flex items-center justify-center transition-all duration-200 ease-linear rounded-md size-8 edit-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center transition-all duration-200 ease-linear rounded-md size-8 remove-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {/* {loader ? (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            zIndex: 6,
            background: "#fff",
            opacity: "1",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="flex justify-center">
            <TailSpin visible={true} height="50" width="50" color="#4fa94d" />
          </div>
        </div>
      ) : null} */}
      <BreadCrumb title="Admins" pageTitle="Admins" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-12 gap-x-5">
        <div className="col-span-12 card 2xl:col-span-12">
          <div className="card-body">
            <div className="flex items-center">
              <h6 className="text-15 grow">Admins</h6>
              <div className="shrink-0">
                <button
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  onClick={toggle}
                >
                  <Plus className="inline-block size-4" />{" "}
                  <span className="align-middle">Add Admin</span>
                </button>
              </div>
            </div>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for name, email, phone number etc..."
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
              </div>
            </form>
          </div>
          <div className="card-body">
            <TableContainer
              isPagination={true}
              columns={adminListColumns || []}
              data={adminData || []}
              pageCount={pageCount}
              pagination={pagination}
              totalItems={totalItems}
              onPaginationChange={(updater: any) => {
                const nextState = updater(pagination);
                if (nextState.pageIndex !== pagination.pageIndex)
                  setPagination(nextState);
              }}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      </div>
      {/* Employee Modal */}
      <Drawer
        show={show}
        onHide={toggle}
        id="drawerEnd"
        drawer-end="true"
        className="fixed inset-y-0 flex flex-col w-1/2 transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 z-drawer dark:bg-zink-600"
      >
        <Drawer.Header
          className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
          closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
        >
          <Drawer.Title className="text-15">
            {!!isEdit ? "Edit Admin" : "Add Admin"}
          </Drawer.Title>
          <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
        </Drawer.Header>
        <Drawer.Body className="h-full p-4 overflow-y-auto">
          <form
            className="create-form"
            id="create-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" value="" name="id" id="id" />
            <input type="hidden" value="add" name="action" id="action" />
            <input type="hidden" id="id-field" />
            <div
              id="alert-error-msg"
              className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20"
            ></div>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="contact_name"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="contact_name"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter First Name"
                  name="contact_name"
                  onChange={validation.handleChange}
                  value={validation.values.contact_name || ""}
                />
                {validation.touched.contact_name &&
                validation.errors.contact_name ? (
                  <p className="text-red-400">
                    {validation.errors.contact_name}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="contact_phone"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="contact_phone"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Phone Number"
                  name="contact_phone"
                  onChange={validation.handleChange}
                  value={validation.values.contact_phone || ""}
                />
                {validation.touched.contact_phone &&
                validation.errors.contact_phone ? (
                  <p className="text-red-400">
                    {validation.errors.contact_phone}
                  </p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="contact_email"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="contact_email"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter Email "
                  name="contact_email"
                  onChange={validation.handleChange}
                  value={validation.values.contact_email || ""}
                />
                {validation.touched.contact_email &&
                validation.errors.contact_email ? (
                  <p className="text-red-400">
                    {validation.errors.contact_email}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-6">
                <label className="inline-block mb-2 text-base font-medium">
                  Gender
                </label>
                <select
                  onChange={validation.handleChange}
                  value={validation.values.gender || ""}
                  name="gender"
                  id="statusgender"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {validation.touched?.gender && validation.errors?.gender ? (
                  <div className="mt-1 text-sm text-red-500">
                    {validation.errors?.gender}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                id="close-modal"
                data-modal-close="addEmployeeModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                id="addNew"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update Admin" : "Add Admin"}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>
    </React.Fragment>
  );
};

export default Admins;
