import { Link } from "react-router-dom";
import { cn } from "helpers/utils";
import { Send } from "lucide-react";

type HeaderProps = {
	className?: string;
	publish: () => void;
};
function BlogHeader(props: HeaderProps) {
	return (
		<header
			className={cn(
				"h-[9vh] border-b border-zinc-200 flex items-center justify-between md:px-[4rem] px-4 relative bg-white",
				props.className
			)}>
			<Link
				to='/'
				className='flex items-center gap-2'>
				<img
					src='/icons/LOGO.svg'
					alt='Logo'
					className='w-[2.5rem] rounded-lg'
				/>
			</Link>

			<h1 className='font-grotesk text-2xl font-medium hidden md:flex items-center justify-center text-zinc-600 gap-8 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
				Create Post
			</h1>

			<div>
				<button
					className='flex justify-center items-center text-sm md:gap-2 gap-1 bg-app-primary hover:bg-blue-700 text-white px-4 md:px-6 py-2 rounded-lg transition-colors'
					onClick={props.publish}>
					Publish
					<Send className='w-4 h-4' />
				</button>
			</div>
		</header>
	);
}

export default BlogHeader;
