import { createClient, SupabaseClient } from "@supabase/supabase-js";

const supabaseUrl: string =
	process.env.VITE_SUPABASE_URL || "https://cqfftuqopjbrvaesmuyd.supabase.co";
const SupabaseAnonKey: string =
	process.env.VITE_PUBLIC_SUPABASE_KEY ||
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNxZmZ0dXFvcGpicnZhZXNtdXlkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzc1NDM3MjYsImV4cCI6MjA1MzExOTcyNn0.qb0TiNtp-8m-_3qKFkRnc4vqBTpdKsIzW7No_nd4kxs";

const supabase: SupabaseClient = createClient(supabaseUrl, SupabaseAnonKey);

export default supabase;
