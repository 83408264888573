import toast from "react-hot-toast";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}
const showToast = {
	message: (message: string) => {
		toast.remove();
		toast(message);
	},
	success: (message: string) => {
		toast.remove();
		toast.success(message);
	},
	error: (message: string) => {
		toast.remove();
		toast.error(message);
	},
	loading: (message: string) => {
		toast.remove();
		toast.loading(message);
	},
	remove: () => {
		toast.remove();
	},
};

export const calculateReadingTime = (content: string): number => {
	// Average reading speed (words per minute)
	const WORDS_PER_MINUTE = 225;

	// Clean the content of markdown syntax and extra spaces
	const cleanContent = content
		.replace(/[#*_[\]()]/g, "") // Remove markdown syntax
		.replace(/\s+/g, " ") // Replace multiple spaces with single space
		.trim();

	// Count words
	const wordCount = cleanContent.split(/\s+/).length;

	// Calculate reading time in minutes
	const minutes = Math.ceil(wordCount / WORDS_PER_MINUTE);

	return minutes;
};

export const formatDate = (date: Date | string): string => {
	const d = new Date(date);
	return d.toLocaleDateString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
	});
};

export default showToast;
