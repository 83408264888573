import React from "react";

const AuthIcon = () => {
  return (
    <React.Fragment>
      <div className="absolute hidden opacity-50 ltr:-left-16 rtl:-right-16 -top-10 md:block">
        <svg
          version="1.2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 125 316"
          width="125"
          height="316"
        >
          <title>&lt;Group&gt;</title>
          <g id="&lt;Group&gt;">
            <path
              id="&lt;Path&gt;"
              className="fill-custom-100/50 dark:fill-custom-950/50"
              d="m23.4 221.8l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-100 dark:fill-custom-950"
              d="m31.2 229.6l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-200/50 dark:fill-custom-900/50"
              d="m39 237.4l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-200/75 dark:fill-custom-900/75"
              d="m46.8 245.2l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-200 dark:fill-custom-900"
              d="m54.6 253.1l-1.3-3.1v-315.4l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-300/50 dark:fill-custom-800/50"
              d="m62.4 260.9l-1.2-3.1v-315.4l1.2 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-300/75 dark:fill-custom-800/75"
              d="m70.3 268.7l-1.3-3.1v-315.4l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-300 dark:fill-custom-800"
              d="m78.1 276.5l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-400/50 dark:fill-custom-700/50"
              d="m85.9 284.3l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-400/75 dark:fill-custom-700/75"
              d="m93.7 292.1l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-400 dark:fill-custom-700"
              d="m101.5 299.9l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-500/50 dark:fill-custom-600/50"
              d="m109.3 307.8l-1.3-3.1v-315.4l1.3 3.1z"
            />
          </g>
        </svg>
      </div>

      <div className="absolute hidden -rotate-180 opacity-50 ltr:-right-16 rtl:-left-16 -bottom-10 md:block">
        <svg
          version="1.2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 125 316"
          width="125"
          height="316"
        >
          <title>&lt;Group&gt;</title>
          <g id="&lt;Group&gt;">
            <path
              id="&lt;Path&gt;"
              className="fill-custom-100/50 dark:fill-custom-950/50"
              d="m23.4 221.8l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-100 dark:fill-custom-950"
              d="m31.2 229.6l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-200/50 dark:fill-custom-900/50"
              d="m39 237.4l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-200/75 dark:fill-custom-900/75"
              d="m46.8 245.2l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-200 dark:fill-custom-900"
              d="m54.6 253.1l-1.3-3.1v-315.4l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-300/50 dark:fill-custom-800/50"
              d="m62.4 260.9l-1.2-3.1v-315.4l1.2 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-300/75 dark:fill-custom-800/75"
              d="m70.3 268.7l-1.3-3.1v-315.4l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-300 dark:fill-custom-800"
              d="m78.1 276.5l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-400/50 dark:fill-custom-700/50"
              d="m85.9 284.3l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-400/75 dark:fill-custom-700/75"
              d="m93.7 292.1l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-400 dark:fill-custom-700"
              d="m101.5 299.9l-1.3-3.1v-315.3l1.3 3.1z"
            />
            <path
              id="&lt;Path&gt;"
              className="fill-custom-500/50 dark:fill-custom-600/50"
              d="m109.3 307.8l-1.3-3.1v-315.4l1.3 3.1z"
            />
          </g>
        </svg>
      </div>
    </React.Fragment>
  );
};

export default AuthIcon;
