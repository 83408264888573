import { Eye, FilePenLine } from "lucide-react";
import { Fragment } from "react";
import BlogContent from "./BlogContent";
import { BlogDetail } from "./blog-type";

interface PreviewToastProps {
	switchToPreview: () => void;
}

const PreviewToast: React.FC<PreviewToastProps> = ({ switchToPreview }) => (
	<div className='animate-enter z-50  w-[22rem] bg-white shadow-lg rounded-lg pointer-events-auto flex fixed bottom-5 right-5 border border-zinc-200 items-center px-3'>
		<div className='flex-1 w-0 py-4 px-1 flex items-center gap-2'>
			<div className='bg-blue-50 p-2 rounded-full animate-pulse'>
				<Eye className='w-5 h-5 text-blue-600' />
			</div>
			<h3 className='text-sm font-medium text-zinc-800'>Preview Mode</h3>
		</div>
		<button
			className='flex items-center gap-2 px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors duration-200 h-fit'
			onClick={switchToPreview}>
			<FilePenLine className='w-5 h-5' />
			<span className='font-medium text-sm'>Back to Editor</span>
		</button>
	</div>
);

const BlogPreview = ({
	blogDetails,
	switchToPreview,
}: {
	blogDetails: BlogDetail;
	switchToPreview: () => void;
}) => {
	return (
		<Fragment>
			{blogDetails.imageFile && (
				<img
					src={URL.createObjectURL(blogDetails.imageFile!)}
					alt=''
					className='w-[80%] rounded-xl mx-auto mt-12 h-[70vh] object-cover'
				/>
			)}
			<div className='w-[57%] mx-auto mt-10'>
				<div className='flex items-center justify-between'>
					<div className='flex gap-2 items-center'>
						<img
							src='/img/no-profile.jpg'
							alt=''
							className='w-12 h-12 rounded-full'
						/>
						<div>
							<h1 className='font-grotesk text-lg font-medium text-zinc-700'>
								{blogDetails.author}
							</h1>
							<p className='font-poppins text-sm text-zinc-500'>
								{"Posted on Aug 12, 2024"}
							</p>
						</div>
					</div>
					<div>
						<div className='bg-gray-100 px-3 py-1 rounded-[.7rem] font-grotesk'>
							<span className='text-sm text-gray-500'>
								{blogDetails.tag}
							</span>
						</div>
					</div>
				</div>
				<div className='mt-6'>
					<h1 className='text-5xl font-medium font-montserrat text-gray-600 leading-[3.9rem] tracking-tighter'>
						{blogDetails.title}
					</h1>
				</div>
				<div className='mt-12'>
					<BlogContent content={blogDetails.content} />
				</div>
			</div>
			<PreviewToast switchToPreview={switchToPreview} />
		</Fragment>
	);
};

export default BlogPreview;
