import React from "react";
import { Route, Routes } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import Layout from "Layout";
import NonAuthLayout from "Layout/NonLayout";
import AuthProtected from "./AuthProtected";
import { BlogProvider } from "components/blog/BlogProvider";
import CreateBlog from "pages/CreateBlog";
import { Toaster } from "react-hot-toast";

const RouteIndex = () => {
	return (
		<React.Fragment>
			<Routes>
				{authProtectedRoutes.map((route: any, idx: number) => (
					<Route
						key={idx}
						path={route.path}
						element={
							<AuthProtected>
								<Layout>
									<route.component />
								</Layout>
							</AuthProtected>
						}
					/>
				))}
				{publicRoutes.map((route: any, idx: number) => (
					<Route
						path={route.path}
						key={idx}
						element={
							<NonAuthLayout>
								<route.component />
							</NonAuthLayout>
						}
					/>
				))}
				<Route
					path='/create'
					key='id'
					element={
						<BlogProvider>
							<Toaster
								position='bottom-right'
								reverseOrder={false}
								gutter={8}
							/>
							<AuthProtected>
								<CreateBlog />
							</AuthProtected>
						</BlogProvider>
					}></Route>
			</Routes>
		</React.Fragment>
	);
};

export default RouteIndex;
