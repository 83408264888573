const Footer = () => {
	return (
		<>
			<footer className='ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm absolute right-0 bottom-0 px-4 h-14 group-data-[layout=horizontal]:ltr:left-0  group-data-[layout=horizontal]:rtl:right-0 left-0 border-t py-3 flex items-center dark:border-zink-600'>
				<div className='group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl w-full'>
					<div className='grid items-center grid-cols-1 text-center lg:grid-cols-2 text-slate-400 dark:text-zink-200 ltr:lg:text-left rtl:lg:text-right'>
						<div>{new Date().getFullYear()} © Curriculo.</div>
						<div className='hidden lg:block'>
							<div className='ltr:text-right rtl:text-left'>
								Design & Develop by Team at AntCreatives
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
