import React, { Fragment, useEffect, useRef, useState } from "react";
import { Image, Eye, Upload } from "lucide-react";
import BlogHeader from "components/blog/Header";
import BlogPreview from "components/blog/BlogPreview";
import { BlogDetail } from "components/blog/blog-type";
import Footer from "components/Footer";
import useBlog from "hooks/useBlog";
import showToast, { calculateReadingTime } from "helpers/utils";

interface AutoResizeTextAreaProps {
	text: string;
	SetText: (value: string) => void;
	sampleText?: string;
	className?: string;
}

const AutoResizeTextArea: React.FC<AutoResizeTextAreaProps> = ({
	text,
	SetText,
	className = "",
}) => {
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);

	const adjustHeight = (): void => {
		const textarea = textareaRef.current;
		if (textarea) {
			// Reset height to auto to get the correct scrollHeight
			textarea.style.height = "auto";
			// Set the height to match the content
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	};

	// Adjust height when text changes
	useEffect(() => {
		adjustHeight();
	}, [text]);

	// Adjust height on initial render
	useEffect(() => {
		adjustHeight();
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
		SetText(e.currentTarget.value);
	};

	return (
		<textarea
			ref={textareaRef}
			onChange={handleChange}
			value={text}
			placeholder='// Start typing...'
			className={` text-md placeholder:text-zinc-400 focus:outline-none w-full  overflow-y-auto ${className}`}
			rows={1}
		/>
	);
};

const BlogEditor = ({
	blogDetails,
	handleChangeBlogDetails,
	text,
	setText,
	handleFileClick,
	imageFileRef,
	upLoadImage,
	switchToPreview,
}: {
	blogDetails: BlogDetail;
	handleChangeBlogDetails: (e: React.ChangeEvent<HTMLInputElement>) => void;
	text: string;
	setText: (value: string) => void;
	handleFileClick: () => void;
	imageFileRef: React.RefObject<HTMLInputElement>;
	upLoadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
	switchToPreview: () => void;
}) => {
	const markdownFileRef = useRef<HTMLInputElement>(null);
	const handleMarkdownUpload = async (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		try {
			const file = e.target.files?.[0];

			// Check if file exists and is markdown
			if (!file) return;
			if (!file.name.endsWith(".md")) {
				alert("Please upload only markdown (.md) files");
				return;
			}

			// Read file content
			const reader = new FileReader();
			reader.readAsText(file);

			reader.onload = (event) => {
				const content = event.target?.result as string;
				setText(content);
			};

			reader.onerror = () => {
				showToast.error("Error reading file");
			};
		} catch (error) {
			console.error("Error uploading markdown:", error);
			showToast.error("Failed to upload file");
		}
	};

	return (
		<Fragment>
			<div className='flex justify-between px-8 my-7 gap-8 mx-auto w-[90%] h-[75vh]'>
				<div className='overflow-auto w-full'>
					<article className='border border-zinc-200 rounded-xl h-full overflow-hidden flex flex-col bg-white'>
						<div
							className='flex items-center gap-1 text-zinc-600 border-b border-b-zinc-200 p-4 bg-gray-50 cursor-pointer hover:bg-gray-100 transition-colors'
							onClick={handleFileClick}>
							<input
								type='file'
								name='cover-image'
								className='hidden'
								id='cover-image'
								onChange={upLoadImage}
								ref={imageFileRef}
							/>
							<Image className='w-6 h-6' />
							<h1 className='font-mono font-medium text-lg'>
								Upload Cover Image
							</h1>
						</div>
						<div className='px-4 pt-2 text-md flex-1 flex flex-col overflow-y-auto'>
							<div className='font-mono'>
								<p className='mb-1 text-zinc-500'>----</p>
								<div className='flex items-center gap-1 mb-1 text-zinc-600'>
									<p>author:</p>{" "}
									<input
										className='w-full focus:outline-none bg-transparent font-mono'
										onChange={handleChangeBlogDetails}
										type='text'
										name='author'
										placeholder='Your name'
										value={blogDetails.author}
									/>
								</div>
								<div className='flex items-center gap-1 mb-1 text-zinc-600'>
									<p>role:</p>{" "}
									<input
										className='w-full focus:outline-none bg-transparent font-mono'
										onChange={handleChangeBlogDetails}
										type='text'
										name='role'
										placeholder='Your role'
										value={blogDetails.role}
									/>
								</div>
								<div className='flex items-center gap-1 mb-1 text-zinc-600'>
									<p>title:</p>{" "}
									<input
										className='w-full focus:outline-none bg-transparent font-mono'
										onChange={handleChangeBlogDetails}
										type='text'
										name='title'
										placeholder='Title'
										value={blogDetails.title}
									/>
								</div>
								<div className='flex items-center gap-1 mb-1 text-zinc-600'>
									<p>description:</p>{" "}
									<input
										className='w-full focus:outline-none bg-transparent font-mono'
										onChange={handleChangeBlogDetails}
										type='text'
										name='description'
										placeholder='Your Description ...'
										value={blogDetails.description}
									/>
								</div>
								<div className='flex items-center gap-1 mb-1 text-zinc-600'>
									<p>cover_image:</p>{" "}
									<input
										className='w-full focus:outline-none bg-transparent font-mono'
										onChange={handleChangeBlogDetails}
										type='text'
										name='cover_image'
										placeholder='cover_image.jpg'
										value={blogDetails.cover_image}
										disabled
									/>
								</div>
								<div className='flex items-center gap-1 mb-1 text-zinc-600'>
									<p>tag:</p>{" "}
									<input
										className='w-full focus:outline-none bg-transparent font-mono'
										onChange={handleChangeBlogDetails}
										type='text'
										name='tag'
										placeholder='Simple Tag'
										value={blogDetails.tag}
									/>
								</div>
								<p className='text-zinc-500'>-----</p>
							</div>

							<AutoResizeTextArea
								text={text}
								SetText={setText}
								className='flex-1 font-mono text-zinc-700 mt-4'
							/>
						</div>
					</article>
				</div>
				<MarkdownEditorUI />
			</div>
			<input
				type='file'
				accept='.md'
				onChange={handleMarkdownUpload}
				className='hidden'
				ref={markdownFileRef} // If you want to trigger it from a button
			/>
			<div className='px-8 gap-8 mx-auto w-[90%] flex items-center'>
				<button
					className='flex items-center gap-2 px-4 py-2 text-gray-700 hover:text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors duration-200'
					onClick={switchToPreview}>
					<Eye className='w-5 h-5' />
					<span className='font-medium'>Preview</span>
				</button>

				<button
					className='flex items-center gap-2 px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors duration-200'
					onClick={() => markdownFileRef.current?.click()}>
					<Upload className='w-5 h-5' />
					<span className='font-medium'>Upload File (.md)</span>
				</button>
			</div>
		</Fragment>
	);
};

const MarkdownGuide = () => {
	const syntaxItems = [
		{ markdown: "# Header", rendered: "H1 Header" },
		{ markdown: "######", rendered: "H6 Header" },
		{ markdown: "*italics* or _italics_", rendered: "italics" },
		{ markdown: "**bold**", rendered: "bold" },
		{ markdown: "[Link](https://...)", rendered: "Link", isLink: true },
		{
			markdown: "* item 1\n* item 2",
			rendered: ["item 1", "item 2"],
			isList: true,
		},
		{
			markdown: "1. item 1\n2. item 2",
			rendered: ["item 1", "item 2"],
			isNumberedList: true,
		},
		{ markdown: "> quoted text", rendered: "quoted text", isQuote: true },
		{ markdown: "`inline code`", rendered: "inline code", isCode: true },
		{
			markdown: "```\ncode block\n```",
			rendered: "code block",
			isCodeBlock: true,
		},
	];

	return (
		<div className='max-w-3xl mx-auto bg-white p-6 text-sm rounded-lg shadow-lg border border-zinc-300'>
			<div className='divide-y'>
				{syntaxItems.map((item, index) => (
					<div
						key={index}
						className='grid grid-cols-2 py-3'>
						<div className='font-mono text-gray-600'>
							{item.markdown.split("\n").map((line, i) => (
								<div key={i}>{line}</div>
							))}
						</div>

						<div>
							{item.isList && (
								<ul className='list-disc ml-5'>
									{(item.rendered as string[]).map((li, i) => (
										<li key={i}>{li}</li>
									))}
								</ul>
							)}

							{item.isNumberedList && (
								<ol className='list-decimal ml-5'>
									{(item.rendered as string[]).map((li, i) => (
										<li key={i}>{li}</li>
									))}
								</ol>
							)}

							{item.isLink && (
								<span className='text-blue-600'>{item.rendered}</span>
							)}

							{item.isQuote && (
								<div className='border-l-4 border-gray-300 pl-4'>
									{item.rendered}
								</div>
							)}

							{item.isCode && (
								<code className='bg-gray-100 px-2 py-1 rounded'>
									{item.rendered}
								</code>
							)}

							{item.isCodeBlock && (
								<div className='bg-gray-100 p-4 rounded'>
									<code>{item.rendered}</code>
								</div>
							)}

							{!item.isList &&
								!item.isNumberedList &&
								!item.isLink &&
								!item.isQuote &&
								!item.isCode &&
								!item.isCodeBlock && <div>{item.rendered}</div>}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const MarkdownEditorUI = () => {
	return (
		<div className=' text-zinc-700 p-6 h-full overflow-auto w-[42%]'>
			<div className='max-w-3xl mx-auto'>
				{/* Header Notice */}

				{/* Publishing Tips Section */}
				<section className='max-w-3xl mx-auto'>
					<h1 className='text-3xl font-bold  font-grotesk text-zinc-600 mb-6'>
						Publishing Tips
					</h1>
					<ul className='space-y-8 list-disc pl-6 '>
						<li className='text-[1rem] text-zinc-700'>
							Hook readers with a strong opening - use a compelling
							question, statistic, or story that immediately captures
							attention and establishes why your topic matters.
						</li>

						<li className='text-[1rem] text-zinc-700'>
							Break up long paragraphs into digestible chunks - aim for
							3-4 sentences per paragraph to improve readability and keep
							readers engaged.
						</li>

						<li className='text-[1rem] text-zinc-700'>
							Include relevant data and research to support your
							arguments - credible sources strengthen your points and add
							value for readers.
						</li>

						<li className='text-[1rem] text-zinc-700'>
							End with actionable takeaways - give readers specific steps
							or insights they can apply after reading your post.
						</li>
					</ul>
				</section>

				{/* Editor Basics Section */}
				<section className='mt-10'>
					<h1 className='text-3xl font-bold mb-3 font-grotesk text-zinc-600'>
						Editor Basics
					</h1>
					<p className='text-lg mb-4'>
						Use <span className='text-blue-400'>Markdown</span> to write
						and format posts.
					</p>
					<MarkdownGuide />
				</section>
			</div>
		</div>
	);
};

export default function CreateBlog() {
	const { createBlog } = useBlog();
	const [text, setText] = useState("");
	const [preview, setPreview] = useState(false);

	const imageFileRef = useRef<HTMLInputElement>(null);

	const [blogDetails, setBlogDetails] = useState<BlogDetail>({
		author: "",
		title: "",
		description: "",
		cover_image: "",
		role: "",
		imageFile: null,
		tag: "",
		content: "",
	});

	const handleFileClick = () => {
		imageFileRef.current?.click();
	};

	const upLoadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			const files = e.target.files;
			if (files) {
				setBlogDetails((prev) => ({
					...prev,
					imageFile: files[0],
					cover_image: files[0].name,
				}));
			}
		} catch (err) {
			console.log(err);
		}
	};

	const switchToPreview = () => {
		setBlogDetails((prev) => ({
			...prev,
			content: text,
		}));
		setPreview((value) => !value);
	};

	const handleChangeBlogDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		setBlogDetails((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const publish = async () => {
		if (!blogDetails.cover_image) {
			showToast.error("Upload a cover Image to publish");
			return;
		}
		if (!blogDetails.author) {
			showToast.error("You have not filled you name");
			return;
		}
		if (!blogDetails.description) {
			showToast.error("You have not filled you name");
			return;
		}

		if (!blogDetails.tag) {
			showToast.error("A short Tag is required");
		}
		try {
			const newBlog = {
				...blogDetails,
				readTime: calculateReadingTime(blogDetails.content),
				created_at: new Date(),
			};
			showToast.loading("Publishing Post");
			await createBlog(newBlog);
			showToast.success("Blog Published successfully");
		} catch (error) {
			console.error("Error :", error);
			showToast.error("Error while publishing blog");
		}
	};

	// useEffect(() => {
	// 	const savedData = sessionStorage.getItem("editBlogData");

	// 	sessionStorage.removeItem("editBlogData");
	// }, []);

	return (
		<Fragment>
			<BlogHeader publish={publish} />

			{preview ? (
				<BlogPreview
					blogDetails={blogDetails}
					switchToPreview={switchToPreview}
				/>
			) : (
				<BlogEditor
					blogDetails={blogDetails}
					handleChangeBlogDetails={handleChangeBlogDetails}
					text={text}
					setText={setText}
					handleFileClick={handleFileClick}
					imageFileRef={imageFileRef}
					upLoadImage={upLoadImage}
					switchToPreview={switchToPreview}
				/>
			)}
			<Footer />
		</Fragment>
	);
}
