import { Instagram } from "lucide-react";
import socialLinks from "locales/socials.json";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer className='bg-white py-16 px-4'>
			<div className='max-w-7xl mx-auto text-zinc-600'>
				<div className='grid grid-cols-1 md:grid-cols-4 gap-8 mb-12'>
					{/* Logo and Description Section */}
					<div className='col-span-1'>
						<div className='flex items-center mb-6'>
							<img
								src='/icons/LOGO.svg'
								alt='Logo'
								className='w-[2.5rem] rounded-lg'
							/>
						</div>
						<p className='text-gray-400 mb-6'>
							Together, we can make a real impact in communities around
							the world.
						</p>
					</div>

					{/* Quick Links */}
					<div className='space-y-4'>
						<h3 className='font-semibold text-lg font-grotesk text-zinc-700'>
							Quick Links
						</h3>
						<div className='flex flex-col space-y-2 '>
							<a className='text-zinc-500'>Donation</a>
							<a className='text-zinc-500'>About us</a>
							<a className='text-zinc-500'>Programs</a>
						</div>
					</div>

					{/* More Links */}
					<div className='space-y-4'>
						<h3 className='font-semibold text-lg font-grotesk text-zinc-700'>
							More
						</h3>
						<div className='flex flex-col space-y-2'>
							<Link to='/blogs'>Blogs</Link>
							<a
								className='text-zinc-500'
								href='/blogs-details'>
								Blogs details
							</a>
							<a className='text-zinc-500'>Testimonials</a>
						</div>
					</div>

					{/* Legal & Policy Links */}
					<div className='space-y-4'>
						<h3 className='font-semibold text-lg font-grotesk text-zinc-700'>
							Legal & Policy Links
						</h3>
						<div className='flex flex-col space-y-2 '>
							<Link
								className='text-zinc-500'
								to='/privacy'>
								Privacy Policy
							</Link>
							<a className='text-zinc-500'>Contact Us</a>
						</div>
					</div>
				</div>

				{/* Bottom Section */}
				<div className='pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center'>
					<p className='text-gray-500 text-sm mb-4 md:mb-0'>
						Copyright © {new Date().getFullYear()} Curriculo. All Rights
						Reserved.
					</p>
					<div className='flex items-center gap-6 opacity-60'>
						<a
							href={socialLinks.meta}
							className='text-gray-600 hover:text-gray-900 transition-colors'>
							<img
								src='/icons/facebook.svg'
								className='w-5 h-5'
								alt='facebook'
							/>
						</a>
						<a
							href={socialLinks.instagram}
							className='text-gray-600 hover:text-gray-900 transition-colors'>
							<Instagram className='w-5 h-5' />
						</a>
						<a
							href={socialLinks.x}
							className='text-gray-600 hover:text-gray-900 transition-colors'>
							<img
								src='/icons/x-dark.svg'
								className='w-5 h-5'
								alt='x'
							/>
						</a>
						<a
							href={socialLinks.Reddit}
							className='text-gray-600 hover:text-gray-900 transition-colors'>
							<img
								src='/icons/reddit.svg'
								className='w-5 h-5'
								alt='reddit'
							/>
						</a>
						<a
							href={socialLinks.linkedin}
							className='text-gray-600 hover:text-gray-900 transition-colors'>
							<img
								src='/icons/linkedIn.svg'
								className='w-5 h-5'
								alt='linkedin'
							/>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
