import { useEffect, useCallback, useState } from "react";
// import { useNavigate } from "react-router";
import supabase from "helpers/supaclient";
import showToast from "helpers/utils";
import { BlogPost } from "components/blog/blog-type";
import { useBlogContext } from "components/blog/BlogProvider";

export default function useBlog() {
	const { blogs, setBlogs } = useBlogContext();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (blogs.length === 0) {
			setLoading(true);
			fetchBlogs();
			setLoading(false);
		}
	}, []);

	const getBlogById = useCallback(
		(id: number) => {
			return blogs.find((blog) => blog.id === id);
		},
		[blogs]
	);

	const fetchBlogs = async (): Promise<BlogPost[] | undefined> => {
		try {
			const { data: blogs, error } = await supabase
				.from("blogs")
				.select("*")
				.order("id", { ascending: false });

			if (error) throw error;

			if (blogs) {
				console.log(blogs);
				setBlogs(blogs);
				return blogs;
			}
		} catch (error) {
			console.error("Error fetching posts:", error);
			return undefined;
		}
	};

	const createBlog = async (
		newBlogDetails: Omit<BlogPost, "id">
	): Promise<void> => {
		try {
			const { imageFile, ...rest } = newBlogDetails;
			const imageUrl = await uploadImage(imageFile!);

			const { data, error } = await supabase
				.from("blogs")
				.insert([
					{
						...rest,
						created_at: new Date(),
						cover_image: imageUrl,
					},
				])
				.select();

			if (error) throw error;

			if (data) {
				setBlogs((prev) => [...prev, ...data]);
				showToast.success("Article Successfully created!");
			}
		} catch (error) {
			console.error("Error creating blog:", error);
			showToast.error("An error occurred");
			throw error;
		}
	};

	const uploadImage = async (file: File): Promise<string | null> => {
		try {
			const fileExt = file.name.split(".").pop();
			const fileName = `${Math.random()}.${fileExt}`;
			const filePath = `cover-images/${fileName}`;

			const { error: uploadError } = await supabase.storage
				.from("blog_images")
				.upload(filePath, file);

			if (uploadError) throw uploadError;

			const {
				data: { publicUrl },
			} = supabase.storage.from("blog_images").getPublicUrl(filePath);

			return publicUrl;
		} catch (error) {
			console.error("Error uploading image:", error);
			return null;
		}
	};

	return {
		blogs,
		getBlogById,
		fetchBlogs,
		createBlog,
		loading,
	};
}
